<template>
  <div>
    <AppHeader/>
    <b-container class="main-content">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </b-container>
  </div>
</template>

<script>
import AppHeader from '@/views/Header.vue'
import { loadLanguageAsync, navigatorCloseLanguage } from '@/plugins/i18n'

export default {
  components: {
    AppHeader
  },
  methods: {
    changeLang: function (lang) {
      loadLanguageAsync(lang)
    }
  },
  created: function () {
    navigatorCloseLanguage()
  }
}
</script>

<style>
  .main-content {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  h2, h3 {
    margin-top: 40px;
  }
  .btn {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 768px) {
    .btn {
      width: 100%;
    }
  }
</style>
