<template>
  <b-navbar toggleable="md" type="dark" variant="dark" :sticky="true">
    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
    <b-navbar-brand :to="{ name: 'home' }"><b-img :src="require('@/assets/logo.png')" class="d-inline-block align-top brand-image" rounded></b-img></b-navbar-brand>
    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav>
        <b-nav-item :to="{ name: 'home' }" exact>{{ $t('header.home') }}</b-nav-item>
        <b-nav-item-dropdown :text="$t('header.tuto')" extra-toggle-classes="nav-link-custom">
          <b-dropdown-item :to="{ name: 'curl' }" exact>{{ $t('header.curl') }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'v2Tov3' }" exact>{{ $t('header.v2Tov3') }}</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :to="{ name: 'simulator-wizard' }">{{ $t('header.simulator') }}</b-nav-item>
        <b-nav-item :to="{ name: 'testapp-introduction' }">{{ $t('header.testapp') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <language-switcher/>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher'

export default {
  components: {
    LanguageSwitcher
  }
}
</script>

<style scoped>
.brand-image {
  height: 40px;
}
</style>
