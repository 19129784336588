import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
const Simulator = () => import(/* webpackChunkName: "group-simulator" */ './views/Simulator')
const SimulatorIntroduction = () => import(/* webpackChunkName: "group-simulator" */ './views/simulator/Introduction')
const SimulatorManual = () => import(/* webpackChunkName: "group-simulator" */ './views/simulator/Manual')
const SimulatorResult = () => import(/* webpackChunkName: "group-simulator" */ './views/simulator/Result')
const SimulatorWizard = () => import(/* webpackChunkName: "group-simulator" */ './views/simulator/Wizard')
const TestApp = () => import(/* webpackChunkName: "group-testapp" */ './views/TestApp')
const TestAppSendLicense = () => import(/* webpackChunkName: "group-testapp" */ './views/testapp/SendLicense')
const TestAppManageLicense = () => import(/* webpackChunkName: "group-testapp" */ './views/testapp/ManageLicense')
const TestAppCreateApp = () => import(/* webpackChunkName: "group-testapp" */ './views/testapp/CreateApp')
const TestAppReceiveRedirect = () => import(/* webpackChunkName: "group-testapp" */ './views/testapp/ReceiveRedirect')
const TestAppIntroduction = () => import(/* webpackChunkName: "group-testapp" */ './views/testapp/Introduction')
const TestAppExposeCode = () => import(/* webpackChunkName: "group-testapp" */ './views/testapp/ExposeCode')
const Curl = () => import(/* webpackChunkName: "group-tuto" */ './views/Curl')
const V2ToV3 = () => import(/* webpackChunkName: "group-tuto" */ './views/V2ToV3')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/tuto/curl',
      name: 'curl',
      component: Curl
    },
    {
      path: '/tuto/v2tov3',
      name: 'v2Tov3',
      component: V2ToV3
    },
    {
      path: '/simulator',
      component: Simulator,
      children: [
        {
          path: '',
          name: 'simulator-introduction',
          component: SimulatorIntroduction
        },
        {
          path: 'wizard',
          name: 'simulator-wizard',
          component: SimulatorWizard
        },
        {
          path: 'manual',
          name: 'simulator-manual',
          component: SimulatorManual
        },
        {
          path: 'result',
          name: 'simulator-result',
          component: SimulatorResult
        }
      ]
    },
    {
      path: '/testapp',
      component: TestApp,
      children: [
        {
          path: 'sendlicense',
          name: 'testapp-sendlicense',
          component: TestAppSendLicense
        },
        {
          path: 'managelicense',
          name: 'testapp-managelicense',
          component: TestAppManageLicense
        },
        {
          path: 'createaccount',
          name: 'testapp-createaccount',
          component: TestAppCreateApp
        },
        {
          path: 'receiveredirect',
          name: 'testapp-receiveredirect',
          component: TestAppReceiveRedirect
        },
        {
          path: 'exposecode',
          name: 'testapp-exposecode',
          component: TestAppExposeCode
        },
        {
          path: '',
          name: 'testapp-introduction',
          component: TestAppIntroduction
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
