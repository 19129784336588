<template>
  <b-nav-item-dropdown :text="$t('header.languageswitch')" extra-toggle-classes="nav-link-custom" right>
    <b-dropdown-item v-for="(lang, key, index) in listLang" :key="index" :value="key" @click="changeLang(key)">{{ lang }}</b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { languagesAvailable, loadLanguageAsync } from '@/plugins/i18n'

export default {
  data: function () {
    return {
      listLang: languagesAvailable
    }
  },
  methods: {
    changeLang (lang) {
      loadLanguageAsync(lang)
    }
  }
}
</script>
